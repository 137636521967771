const ENV = process.env.REACT_APP_ENV || "DEV";

let config;

if (ENV === "DEV") {
  config = {
    WS_BASE_URL: "http://localhost:5100",
    DOMAIN_NAME: "http://localhost:3000",
    SOCKET_BASE_URL: "http://localhost:5100",
    DEMO: false,
    GoogleSSO_CLIENT_ID:
      "883883255154-gj9kh583cu9b3kc8iduvqkidjact7f0m.apps.googleusercontent.com",
  };
} else {
  config = {
    WS_BASE_URL: "https://lighthouz.ai/api",
    DOMAIN_NAME: "https://lighthouz.ai",
    SOCKET_BASE_URL: "https://lighthouz.ai",
    DEMO: false,
    GoogleSSO_CLIENT_ID:
      "883883255154-gj9kh583cu9b3kc8iduvqkidjact7f0m.apps.googleusercontent.com",
  };
}

export default config;
