"use client";

import React, { useState } from "react";
import { DefaultPageLayout } from "@/subframe/layouts/DefaultPageLayout";
import * as SubframeCore from "@subframe/core";
import { Button } from "@/subframe/components/Button";
import { Table } from "@/subframe/components/Table";
import { acknowledgementUpload } from "../../network/ApiAxios.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "@/subframe/components/Loader";
import { Accordion } from "@/subframe/components/Accordion";
import FileUpload from "../../views/pages/components/FileUpload";

interface PartDetail {
  POQuantity: number;
  AcknowledgementQuantity: number;
  POUnitPrice: number;
  AcknowledgementUnitPrice: number;
  POTotalPrice: number;
  AcknowledgementTotalPrice: number;
  Status: string;
}

interface MatchingParts {
  [part: string]: PartDetail;
}

function AcknowledgementVsPOChecker() {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedFilesBase64, setSelectedFilesBase64] = useState<string[]>([]);
  const [filenames, setFilenames] = useState<string[]>([]);
  const [fileURL, setFileURL] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const [matchingParts, setMatchingParts] = useState<MatchingParts>({});
  const [mismatchedParts, setMismatchedParts] = useState<MatchingParts>({});
  const [acknowledgementData, setAcknowledgementData] = useState<any>();
  const [matchingComplete, setMatchingComplete] = useState(false);

  const [selectedPOFiles, setSelectedPOFiles] = useState<File[]>([]);
  const [selectedPOFilesBase64, setSelectedPOFilesBase64] = useState<string[]>(
    [],
  );
  const [POfileURL, setPOFileURL] = useState<string | undefined>(undefined);
  const [POFilenames, setPOFilenames] = useState<string[]>([]);

  const handleCompare = () => {
    setMatchingComplete(false);
    setAcknowledgementData(undefined);

    if (selectedFilesBase64[0] && selectedPOFilesBase64[0]) {
      setLoading(true);
      acknowledgementUpload(
        undefined,
        selectedFilesBase64[0],
        selectedPOFilesBase64[0],
        filenames[0],
      )
        .then((res) => {
          const { data } = res;
          if (!data.success) {
            toast.error(data.message);
          }
          setMatchingParts(data.matching_parts || "{}");
          setMismatchedParts(data.mismatched_parts || "{}");
          setAcknowledgementData(data.acknowledgement_details?.response);

          setMatchingComplete(true);

          toast.success("Matching complete successfully");
        })
        .catch((error) => {
          console.error(error);
          toast.error("Error extracting data from Acknowledgement / PO");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Please provide all the information.");
    }
  };

  return (
    <DefaultPageLayout>
      <ToastContainer />
      <div className="flex h-full w-full flex-col items-center gap-6 rounded-md bg-default-background px-6 py-6 mobile:rounded-md mobile:border mobile:border-solid mobile:border-neutral-border mobile:bg-default-background mobile:shadow-sm">
        <div className="flex w-full max-w-[1536px] flex-col items-center gap-6">
          <div className="flex w-full items-center gap-2">
            <div className="flex flex-col items-start gap-2">
              <span className="text-heading-1 font-heading-1 text-default-font">
                Acknowledgement Upload
              </span>
            </div>
          </div>

          <div className="flex w-full items-start gap-2">
            <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2">
              <span className="text-heading-2 font-heading-2 text-default-font">
                Enter Acknowledgement PDF
              </span>
              <FileUpload
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                setSelectedFilesBase64={setSelectedFilesBase64}
                setFilenames={setFilenames}
                setFileURL={setFileURL}
              />
            </div>
          </div>

          <div className="flex w-full items-start gap-2">
            <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2">
              <span className="text-heading-2 font-heading-2 text-default-font">
                Enter Purchase Order PDF
              </span>
              <FileUpload
                selectedFiles={selectedPOFiles}
                setSelectedFiles={setSelectedPOFiles}
                setSelectedFilesBase64={setSelectedPOFilesBase64}
                setFilenames={setPOFilenames}
                setFileURL={setPOFileURL}
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-center gap-2">
            <Button onClick={handleCompare} disabled={loading}>
              Verify and Save
            </Button>
          </div>

          {acknowledgementData && (
            <div className="flex w-full flex-col items-start gap-4 rounded-md border border-solid border-neutral-border bg-default-background shadow-sm">
              <Accordion
                trigger={
                  <div className="flex w-full items-center gap-2 px-6 py-6">
                    <div className="flex grow shrink-0 basis-0 items-center gap-4">
                      <span className="text-heading-2 font-heading-2 text-default-font">
                        Extracted Acknowledgement Data
                      </span>
                    </div>
                    <Accordion.Chevron />
                  </div>
                }
                defaultOpen={true}
              >
                <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4 border-t border-solid border-neutral-border px-6 py-6">
                  <Table
                    header={
                      <Table.HeaderRow>
                        <Table.HeaderCell>Key</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                      </Table.HeaderRow>
                    }
                  >
                    {Object.keys(acknowledgementData).map((key) => {
                      if (key !== "Items") {
                        return (
                          <Table.Row key={key}>
                            <Table.Cell>{key}</Table.Cell>
                            <Table.Cell>
                              {typeof acknowledgementData[key] === "object"
                                ? JSON.stringify(acknowledgementData[key])
                                : acknowledgementData[key] || ""}
                            </Table.Cell>
                          </Table.Row>
                        );
                      } else {
                        return (
                          <Table.Row key="Items">
                            <Table.Cell>Items</Table.Cell>
                            <Table
                              header={
                                <Table.HeaderRow>
                                  <Table.HeaderCell>#</Table.HeaderCell>
                                  {Object.keys(
                                    acknowledgementData.Items[0],
                                  ).map((itemKey) => (
                                    <Table.HeaderCell key={itemKey}>
                                      {itemKey}
                                    </Table.HeaderCell>
                                  ))}
                                </Table.HeaderRow>
                              }
                            >
                              {acknowledgementData.Items.map((item, index) => (
                                <Table.Row key={index}>
                                  <Table.Cell scope="row">
                                    {index + 1}
                                  </Table.Cell>
                                  {Object.keys(item).map(
                                    (itemKey, valIndex) => (
                                      <Table.Cell key={valIndex}>
                                        {item[itemKey]}
                                      </Table.Cell>
                                    ),
                                  )}
                                </Table.Row>
                              ))}
                            </Table>
                          </Table.Row>
                        );
                      }
                    })}
                  </Table>
                </div>
              </Accordion>
            </div>
          )}

          {matchingComplete && (
            <div className="flex w-full flex-col items-start gap-4 rounded-md border border-solid border-neutral-border bg-default-background shadow-sm">
              <Accordion
                trigger={
                  <div className="flex w-full items-center gap-2 px-6 py-6">
                    <div className="flex grow shrink-0 basis-0 items-center gap-4">
                      <span className="text-heading-2 font-heading-2 text-default-font">
                        Acknowledgement Verification report is below
                      </span>
                    </div>
                    <Accordion.Chevron />
                  </div>
                }
                defaultOpen={true}
              >
                <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4 border-t border-solid border-neutral-border px-6 py-6">
                  <Table
                    header={
                      <Table.HeaderRow>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Part</Table.HeaderCell>
                        <Table.HeaderCell>Quantity in PO</Table.HeaderCell>
                        <Table.HeaderCell>
                          Quantity in Acknowledgement
                        </Table.HeaderCell>
                        <Table.HeaderCell>Unit Price in PO</Table.HeaderCell>
                        <Table.HeaderCell>
                          Unit Price in Acknowledgement
                        </Table.HeaderCell>
                        <Table.HeaderCell>Total Price in PO</Table.HeaderCell>
                        <Table.HeaderCell>
                          Total Price in Acknowledgement
                        </Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                      </Table.HeaderRow>
                    }
                  >
                    {Object.entries(mismatchedParts).map(
                      ([part, details], index) => (
                        <Table.Row key={index}>
                          <Table.Cell>
                            <SubframeCore.Icon
                              className="text-heading-2 font-heading-2 text-error-700"
                              name="FeatherAlertCircle"
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <span className="line-clamp-2 text-body-bold font-body-bold text-neutral-700">
                              {part}
                            </span>
                          </Table.Cell>
                          <Table.Cell>{details.POQuantity}</Table.Cell>
                          <Table.Cell>
                            {details.AcknowledgementQuantity}
                          </Table.Cell>
                          <Table.Cell>{details.POUnitPrice}</Table.Cell>
                          <Table.Cell>
                            {details.AcknowledgementUnitPrice}
                          </Table.Cell>
                          <Table.Cell>{details.POTotalPrice}</Table.Cell>
                          <Table.Cell>
                            {details.AcknowledgementTotalPrice}
                          </Table.Cell>
                          <Table.Cell>
                            <span className="line-clamp-2 text-body-bold font-body-bold text-neutral-700">
                              {details.Status}
                            </span>
                          </Table.Cell>
                        </Table.Row>
                      ),
                    )}

                    {Object.entries(matchingParts).map(
                      ([part, details], index) => (
                        <Table.Row key={index}>
                          <Table.Cell>
                            <SubframeCore.Icon
                              className="text-heading-2 font-heading-2 text-success-700"
                              name="FeatherCheckCircle"
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <span className="line-clamp-2 text-body-bold font-body-bold text-neutral-700">
                              {part}
                            </span>
                          </Table.Cell>
                          <Table.Cell>{details.POQuantity}</Table.Cell>
                          <Table.Cell>
                            {details.AcknowledgementQuantity}
                          </Table.Cell>
                          <Table.Cell>{details.POUnitPrice}</Table.Cell>
                          <Table.Cell>
                            {details.AcknowledgementUnitPrice}
                          </Table.Cell>
                          <Table.Cell>{details.POTotalPrice}</Table.Cell>
                          <Table.Cell>
                            {details.AcknowledgementTotalPrice}
                          </Table.Cell>
                          <Table.Cell>
                            <span className="line-clamp-2 text-body-bold font-body-bold text-neutral-700">
                              {details.Status}
                            </span>
                          </Table.Cell>
                        </Table.Row>
                      ),
                    )}
                  </Table>
                </div>
              </Accordion>
            </div>
          )}
          {matchingComplete && (
            <div className="flex w-full items-center justify-center mb-5 gap-2">
              <Button variant={"brand-primary"} size={"large"}>
                Save on ERP
              </Button>
            </div>
          )}

          {loading && (
            <div className="w-full md:w-1/2">
              <div className="text-center">
                <Loader size="large" />
              </div>
            </div>
          )}
        </div>
      </div>
    </DefaultPageLayout>
  );
}

export default AcknowledgementVsPOChecker;
