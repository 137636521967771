import { useEffect } from "react";

export default function LoadingPage() {
  useEffect(() => {
    import("animate.css");
  }, []);
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
      }}
    >
      <img
        src="/lighthouz.png"
        className="animate__animated animate__pulse animate__infinite"
        alt="Loading..."
        style={{ width: "75px" }}
      />
    </div>
  );
}
