import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { checkSession } from "../../network/ApiAxios";

const AuthRoute = ({ component: Component, ...rest }) => {
  // console.log("loading an AuthRoute");
  const history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [sessionValid, setSessionValid] = React.useState(false);

  React.useEffect(() => {
    async function checkSessionValidity() {
      // console.log("checking session");
      const token = localStorage.getItem("token");

      if (token) {
        try {
          // console.log("sending check session call");
          const response = await checkSession(token);
          // console.log("checkSession response: " + JSON.stringify(response));
          const { data } = response;
          if (!data.success) {
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("username");
            history.push("/auth/login");
          } else {
            setSessionValid(true);
          }
          return data.success;
        } catch (err) {
          // console.log("Error while checking token in PrivateRoute: " + err);
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          localStorage.removeItem("username");
          history.push("/auth/login");
        }
      } else {
        // console.log("setting check session valid");
        setSessionValid(false);
      }

      // console.log("setting loading false");

      setLoading(false);
    }

    // console.log("starting checkSessionValidity");
    checkSessionValidity();
  }, [history]);

  return (
    <Route
      {...rest}
      render={(props) =>
        !(
          localStorage.getItem("token") &&
          localStorage.getItem("userId") &&
          loading
        ) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AuthRoute;
