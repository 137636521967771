// @subframe/sync-disable

"use client";
/*
 * Documentation:
 * Default Page Layout — https://app.subframe.com/af20f9683569/library?component=Default+Page+Layout_a57b1c43-310a-493f-b807-8cc88e2452cf
 * Sidebar — https://app.subframe.com/af20f9683569/library?component=Sidebar_1651a160-3525-494a-b02c-03db4e8516b1
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { Sidebar } from "../components/Sidebar";
import { logOut } from "../../network/ApiAxios";

interface DefaultPageLayoutRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
  selected?: string;
}

const DefaultPageLayoutRoot = React.forwardRef<
  HTMLElement,
  DefaultPageLayoutRootProps
>(function DefaultPageLayoutRoot(
  {
    children,
    className,
    selected = "dashboard",
    ...otherProps
  }: DefaultPageLayoutRootProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "flex h-screen w-full items-center bg-default-background",
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <Sidebar logoImage="https://res.cloudinary.com/subframe/image/upload/v1722233690/uploads/3651/png4b5he0443kgqnubht.png">
        <Sidebar.Item
          onClick={() => {
            window.location.href = "/";
          }}
          selected={selected === "dashboard"}
        >
          Dashboard
        </Sidebar.Item>
        <Sidebar.Item
          onClick={() => {
            window.location.href = "/chatbot";
          }}
          icon="FeatherMessageCircle"
          selected={selected === "chatbot"}
        >
          Order with Pam
        </Sidebar.Item>
        <Sidebar.Item
          onClick={() => {
            window.location.href = "/analytics";
          }}
          icon="FeatherDollarSign"
          selected={selected === "analytics"}
        >
          Analytics
        </Sidebar.Item>
        <Sidebar.Item
          selected={selected === "sets"}
          onClick={() => {
            window.location.href = "/orders";
          }}
          icon="FeatherBox"
        >
          Orders
        </Sidebar.Item>
        <Sidebar.Item
          selected={selected === "parts"}
          onClick={() => {
            window.location.href = "/parts";
          }}
          icon="FeatherWrench"
        >
          Parts
        </Sidebar.Item>
        <Sidebar.Item
          icon="FeatherShieldAlert"
          selected={selected === "alerts"}
          onClick={() => {
            window.location.href = "/alerts";
          }}
        >
          Alerts
        </Sidebar.Item>
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-border" />
        <Sidebar.Item icon="FeatherSettings" selected={selected === "settings"}>
          Settings
        </Sidebar.Item>
        <Sidebar.Item
          icon="FeatherLogOut"
          onClick={(e) => {
            e.preventDefault();
            logOut().then(() => {
              window.location.href = "/auth/login";
            });
          }}
        >
          Sign Out
        </Sidebar.Item>
      </Sidebar>
      {children ? (
        <div className="flex grow shrink-0 basis-0 flex-col items-start gap-4 self-stretch overflow-y-auto">
          {children}
        </div>
      ) : null}
    </div>
  );
});

export const DefaultPageLayout = DefaultPageLayoutRoot;
